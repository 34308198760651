import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CardService} from "../../../modules/order/shopping-cart/card.service";
import {AuthService} from "../../../modules/auth/auth.service";
import {ShopModel} from "../../../core/interfaces/shop.model";
import {ProductsContentItemModel} from "../../../core/interfaces/products/products-data.model";
import {Base} from "../../../core/base";
import {switchMap, takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";
import {NavigationService} from "../../../core/services/navigation-service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {timer} from "rxjs";
import {QuickviewService} from "../../../core/services/quick-view.service";
import {ModalDirective} from 'ngx-bootstrap/modal';
import {WishListService} from "../../../modules/order/wish-list/wish-list.service";
import {GlobalService} from "../../../core/services/global.service";
import {environment} from "../../../../environments/environment";


function parseNumber<T>(value: any, def: T): number | T {
	if (typeof value === 'string') {
		value = parseFloat(value);
	} else if (typeof value !== 'number') {
		value = def;
	}

	return isNaN(value) ? def : value;
}

@Component({
	selector: 'app-product-quick-view',
	templateUrl: './product-quick-view.component.html'
})
export class ProductQuickViewComponent extends Base implements OnInit, AfterViewInit {
	image = environment.imagePath;
	addToCardForm: FormGroup;
	product: ProductsContentItemModel;
	shopId: string;
	shop: ShopModel;
	price: number;
	reward: number;
	size: 'sm' | 'lg' | null = null;
	options = {
		step: 1,
		min: null as null | number,
		max: null as null | number,
		disabled: false,
		readonly: false,
	};

	set min(value: number) {
		this.options.min = parseNumber(value, null);
	}

	@ViewChild('inputElement', {static: true}) inputElementRef!: ElementRef;
	@ViewChild('modal') modal!: ModalDirective;

	get inputElement(): HTMLInputElement {
		return this.inputElementRef.nativeElement;
	}

	get value(): '' | number {
		return this.inputElement.value === '' ? '' : parseFloat(this.inputElement.value);
	}

	onChange = (_: any) => {
	};
	onTouched = () => {
	};
	productCount = 1;
	isLoading: boolean;
	showWishListPopup: boolean;

	constructor(
		private cardService: CardService,
		private globalService: GlobalService,
		private navigationService: NavigationService,
		private fb: FormBuilder,
		private authService: AuthService,
		private wishListService: WishListService,
		private quickview: QuickviewService,
	) {
		super();
	}

	ngOnInit(): void {
		this.initAddToCardForm();

		if (localStorage.getItem('shop') !== 'undefined') {
			this.shop = JSON.parse(localStorage.getItem('shop'));
		}

		this.shopId = this.shop ? this.shop.id : null;
	}

	ngAfterViewInit(): void {
		this.quickview.show$.pipe(
			switchMap(product => {
				this.modal.show();
				this.product = product;
				return timer(150);
			}),
			takeUntil(this.destroy$),
		).subscribe(() => {
			this.getProductById();
			this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
				this.shopId = res?.id;
				this.initAddToCardForm();
			})
		});

		this.modal.onHidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.product = null;
		});
	}


	private initAddToCardForm(): void {
		this.addToCardForm = this.fb.group({
			shop: this.shopId,
			productId: this.product?.id,
			quantity: this.productCount,
			properties: this.fb.group({
				unit: this.fb.group({
					unitId: ['', Validators.required],
					name: ['', Validators.required],
					refId: '',
					contents: ['', Validators.required]
				})
			})
		})
	}

	setUnit(event: any): void {
		this.reward = this.product?.reward;
		this.price = this.product?.price - (this.product?.price * this.product?.discount) / 100;
		this.addToCardForm.get('properties').get('unit').patchValue(event);
		this.price = this.price * event.contents;
		this.reward = this.reward * event.contents;
	}

	addToCard(): void {
		this.cardService.addToCard(this.addToCardForm.getRawValue())
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
				},
				error: (err) => {
				}
			})
	}

	steQuantityByKeyDown(event: any): void {
		this.productCount = +event;
		this.addToCardForm.get('quantity').setValue(this.productCount);
	}

	private getCards(): void {
		this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$)).subscribe(() => {
		})
	}

	plus(): void {
		this.productCount++;
		this.addToCardForm.get('quantity').setValue(this.productCount);
	}

	minus(): void {
		if (this.productCount <= 1) return;
		this.productCount--;
		this.addToCardForm.get('quantity').setValue(this.productCount);
	}

	private getProductById(): void {
		this.isLoading = true;
		const query = {
			id: this.product.id,
			shopId: this.shopId
		};
		if (!this.shopId) {
			delete query.shopId;
		}
		this.globalService.getProductsByShop(query).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<ProductsContentItemModel>) => {
				this.product = res.payload;
				this.price = this.product?.price - (this.product?.price * this.product?.discount) / 100;
				this.reward = this.product?.reward;
				this.product.properties?.unit?.filter(el => {
					if (this.product.unit?.unitCode && el.unitCode === this.product.unit?.unitCode) {
						this.addToCardForm.get('properties').get('unit').patchValue(el);
					}
				})
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	addToWishList(product: any): void {
		if (this.showWishListPopup) {
			return;
		}

		this.showWishListPopup = true;
		this.wishListService.show(this.product).subscribe({
			complete: () => {
				this.showWishListPopup = false;
			},
		});
	}
}

<div class="modal-content" mat-dialog-content>
	<div class="d-flex">
		<button class="quickview__close" (click)="cancel()">
			<app-icons icon="cross-12"></app-icons>
		</button>
		<h1 mat-dialog-title>{{'CHANGE_STATUS_TEXT' | translate}}<b></b></h1>
	<div class="d-flex flex-row justify-content-sm-between mt-3" mat-dialog-actions>
		<button class="btn btn-secondary" (click)="cancel()">
			{{'BUTTON_CANCEL' | translate}}
		</button>
		<button class="btn btn-primary" (click)="changeStatus()">
			{{'BUTTON_CHANGE' | translate}}
		</button>
	</div>
</div>

<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<div class="modal fade" tabindex="-1" role="dialog" bsModal #modal="bs-modal">
	<div class="modal-dialog modal-dialog-centered quickview">
		<div *ngIf="product" class="modal-content">
			<div class="d-flex justify-content-between">
				<div class="product-card__badges m-2">
					<div *ngIf="product?.discount" class="tag-badge tag-badge--sale">{{'TEXT_BADGE_SALE' | translate}}</div>
				</div>
				<button type="button" class="quickview__close" (click)="modal.hide()">
					<app-icons icon="cross-12"></app-icons>
				</button>
			</div>
			<div class="quickview__body">
				<app-product-gallery class="quickview__gallery"
									 layout="quickview"
									 [images]="product?.gallery">
				</app-product-gallery>
				<div class="quickview__product">
					<form [formGroup]="addToCardForm">
						<div class="quickview__product-name">
							{{ product.name }}
						</div>
						<div class="quickview__product-prices-stock">
							<div *ngIf="product?.price" class="quickview__product-prices">
								<div class="quickview__product-price quickview__product-price--current">
									<ng-container *ngIf="product?.discount > 0">
										<div
											class="product__price product__price--old">{{product?.price + ' ₽'}}</div>
										<div class="product__price product__price--new">
											{{price | currency: [''] }}
											₽
										</div>
									</ng-container>
									<ng-container *ngIf="!product?.discount || product?.discount === 0">
										<div
											class="product__price product__price--current">{{price}}
											₽
										</div>
									</ng-container>
								</div>
								<div
									class="quickview__product-price quickview__product-price--current">{{'TABLE_REWARD' | translate}}{{' ' + (reward | number: '1.0-2' || '')}}</div>
							</div>
						</div>
						<div class="quickview__product-form">
							<div class="product-form__body">
								<div class="product-form__row">
									<div class="product-form__title">{{'INPUT_UNIT_LABEL' | translate}}</div>
									<div class="product-form__control">
										<div class="input-radio-label">
											<div class="input-radio-label__list">
												<label *ngFor="let unit of product?.properties?.unit; let i = index"
													   class="input-radio-label__item">
													<input
														type="radio"
														name="material"
														class="input-radio-label__input"
														[checked]="product?.unit?.unitCode && unit?.unitCode === product?.unit?.unitCode"
														(click)="setUnit(unit)"
													>
													<span
														class="input-radio-label__title">{{ unit.name }}</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="quickview__product-actions">
							<div class="quickview__product-actions-item quickview__product-actions-item--quantity">
								<div class="input-number">
									<input
										onlyNumber
										(input)="steQuantityByKeyDown($event.target.value)"
										oninput="this.value = Math.abs(this.value)"
										min=1
										class="input-number__input form-control"
										type="number"
										formControlName="quantity">
									<div class="input-number__add" (mousedown)="plus()"></div>
									<div class="input-number__sub" (mousedown)="minus()"></div>
								</div>
							</div>
							<div class="quickview__product-actions-item quickview__product-actions-item--addtocart">
								<button
									[disabled]="!addToCardForm.get('properties').get('unit').get('unitId').value"
									class="btn btn-primary btn-block"
									(click)="addToCard()"
								>
									{{ 'BUTTON_ADD_TO_CART'|translate }}
								</button>
							</div>
							<div class="quickview__product-actions-item quickview__product-actions-item--wishlist"
							>
								<button class="btn btn-muted btn-icon"
										type="button"
										(click)="addToWishList(product)"
								>
									<app-icons icon="wishlist-16"></app-icons>
								</button>
							</div>
						</div>
					</form>

				</div>

			</div>

		</div>

	</div>
</div>
